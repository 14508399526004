import React from "react";
import cf1 from "./assets/arrow/company_r1.jpg";
import cf2 from "./assets/arrow/company_r.jpg";
import { NavLink } from "react-router-dom";
function Companyformation() {
  return (
    <div class="">
      <div className="row align-items-start">
        <img class="img-fluid" src={cf1} />
      </div>
      <br />
      <div className="row align-items-start">
        <div className="col">
          <h1 style={{ textAlign: "center" }}>Company Formation</h1>
        </div>
      </div>
      <br />
      <br />
      <div className="container">
        <div className="row align-items-start">
          <img class="col-md-6 img-fluid" src={cf2} alt="notload" />

          <div className="col-md-6">
            <h4>
              Company formation is the process of incorporating a business.
              Whether you have a new company or a branch of an existing
              business, whether its private limited, LLC or individual business
              set up we will completely handle the legal proceedings of its
              formation within few days. Upon incorporation, a private limited
              company becomes a separate legal entity; an ‘individual’ that is
              completely distinct from its owners and responsible for its own
              finances, assets and liabilities. We will take care of its
              documentation to bank account procedures to license requirement to
              listing business activities. Additionally we also provide logo
              designing and website development. Further to support the normal
              functioning of the company we will provide audit services and tax
              consultation to keep your business hassle free.
            </h4>
          </div>
        </div>
      </div>
      <br />
      <br />

      <div class="row gx-0">
        <div class="col-sm-6 btn">
          <div
            class="p-5 border"
            style={{
              backgroundColor: "#082032",

              marginLeft: "0px",
            }}
          >
            <NavLink exact to="/submiturcv">
              <a className="nav-link scrollto" href="#services">
                <h1 style={{ color: "white" }}>Submit ur CV</h1>
              </a>
            </NavLink>
          </div>
        </div>
        <div class="col-sm-6 btn">
          <div
            class="p-5 border"
            style={{
              backgroundColor: "#082032",

              marginLeft: "0px",
            }}
          >
            <a
              className="nav-link scrollto"
              href="https://wiseminds.online"
            >
              <h1 style={{ color: "white" }}>Job Search</h1>
            </a>
          </div>
        </div>
      </div>

      <br />
      <br />
    </div>
  );
}

export default Companyformation;
