import React from "react";
import bs from "./assets/arrow/banking_1.jpg";
import bs1 from "./assets/arrow/banking_2.png";
import { NavLink } from "react-router-dom";
function Bankingservices() {
  return (
    <div>
      <div className="row align-items-start">
        <img class="img-fluid" src={bs}  />
      </div>
      <br />
      <div className="row align-items-start">
        <div className="col">
          <h1 style={{ textAlign: "center" }}>Banking Services</h1>
        </div>
      </div>
      <br />
      <br />
      <div className="container">
        <div className="row align-items-start">
          <img
            className="col-md-6"
            src={bs1}
            
            alt="notload"
          />

          <div className="col-md-6">
            <h4>
              One of the first things you may want to do after creating your
              business is to open a bank account for your business. Having a
              business bank account could help you keep your personal and
              business money separate, which can make it easier to keep track of
              your business’s finances, protect your personal finances in case
              of business financial challenges and save you time when you need
              to prepare a tax return.Financial institutions that offer business
              bank accounts may also offer you a business loan, credit loan or
              credit cards. These types of business credit could help you manage
              your business’s finance. However, always be cautious when you’re
              borrowing money. Have a plan for how you can effectively use the
              money and how you’re going to repay the debt before you agree to
              take out a loan.
            </h4>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div class="row gx-0">
        <div class="col-sm-6 btn">
          <div
            class="p-5 border"
            style={{
              backgroundColor: "#082032",

              marginLeft: "0px",
            }}
          >
            <NavLink exact to="/submiturcv">
              <a className="nav-link scrollto" href="#services">
                <h1 style={{ color: "white" }}>Submit ur CV</h1>
              </a>
            </NavLink>
          </div>
        </div>
        <div class="col-sm-6 btn">
          <div
            class="p-5 border"
            style={{
              backgroundColor: "#082032",

              marginLeft: "0px",
            }}
          >
            <a
              className="nav-link scrollto"
              href="https://wiseminds.online"
            >
              <h1 style={{ color: "white" }}>Job Search</h1>
            </a>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
}

export default Bankingservices;
