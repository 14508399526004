import React, {useState} from "react";
import emailjs from "emailjs-com";
// function onClickButton(event) {
//   event.preventDefault();
// }

function Contact() {
  
  
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ycxuh8e",
        "template_7vg4p69",
        e.target,
        "user_wDdcZs2lHJnEdVr1VAr8C"
      )
      .then(
        (result) => {
          alert("Your CV is submitted successfully, Thank You!!");
          e.target.reset();
        },
        (error) => {
          alert("ERROR Please try after some time.");
        }
      );
      setTimeout(() => {
        setSubmitting(false);
      }, 3000)
  };

  const [submitting, setSubmitting] = useState(false);
  
  const handleSubmit = event => {
    event.preventDefault();
   setSubmitting(true);

   
 }

  return (
    <div class="row" style={{ backgroundColor: "#F6F6F6" }}>
     
      <div className="contact-form " style={{ backgroundColor: "#D5D5D5" }}>
        <div className="first-container col-md-6">
          <div className="info-container">
            <div>
              <img className="icon" />
              <h3>Address</h3>
              <p>
                <i class="bi bi-geo-alt-fill"></i>{" "}
                <strong> Address:- Office # 212, 2nd Floor,</strong> <br />
                <strong> Rashid AL Makhawi Building, </strong> <br />
                <strong> Opp. Nasar Club, Oud Metha </strong>
                <br />
                <strong> Dubai UAE </strong> <br />
                <br />
                <i class="bi bi-phone"></i>{" "}
                <strong>Phone: +971-55-2602997</strong> <br />
                <i class="bi bi-telephone-fill"> </i>
                <strong>Landline: 045915242 </strong> <br />
                <i class="bi bi-envelope-fill"></i> <strong>Email:</strong>{" "}
                <strong>info@wiseminds.ae</strong>
                <br />
              </p>
            </div>
            <div>
              {" "}
              <img className="icon" />
              <h3>Lets Talk</h3>
              <p>
                <i className="bi bi-telephone-fill"></i> 045915242
              </p>
            </div>
            <div>
              <img className="icon" />
              <h3>General Support</h3>
              <p>info@wiseminds.ae</p>
            </div>
          </div>
        </div>
        <div className="second-container">
          <h2>Submit Your CV</h2>
          <form onSubmit={sendEmail}> 
            <div className="form-group">
              <label htmlFor="fname-input">Name*</label>
              <input
                name="fname-input"
                type="text"
                placeholder="First name"
                required="required"
              />
              <input type="text"  name="lname-input" placeholder="Last name" required="required" />
            </div>

            <div className="form-group">
              <label htmlFor="email-input">Enter your email*</label>
              <input
                name="email-input"
                type="text"
                placeholder="Eg. example@email.com"
                required="required"
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone-input">Phone number*</label>
              <input
                name="phone-input"
                type="number"
                placeholder="Eg. +971 00000000"
                required="required"
              />
            </div>
            <div className="form-group">
              <label htmlFor="desination-input">Designation*</label>
              <input
                name="desination-input"
                type="text"
                placeholder=" Eg.Software developer"
                required="required"
              />
            </div>
            <div className="form-group">
              <label htmlFor="salary-input">CTC*</label>
              <input
                name="csalary-input"
                type="number"
                placeholder="Current CTC"
                required="required"
              />
              <input
               name="esalary-input"
               type="text"
                placeholder="EXpected CTC"
                required="required"
              />
            </div>
            <div className="form-group">
              <label htmlFor="message-textarea">Short Description</label>
              <textarea
                name="message-textarea"
                placeholder="Enter cover letter"
                defaultValue={""}
              />
            </div>
            <p>Send your CV to wiseminds2021@gmail.com</p>
             <input
                type="submit"
                value="send"
                placeholder="EXpected CTC"
                required="required"
              className="form-control btn btn-primary"
              />
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
