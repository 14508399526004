import React from "react";
import tax from "./assets/arrow/tax.jpg";
import audit from "./assets/arrow/audit.jpg";
import { NavLink } from "react-router-dom";

function Aandb() {
  return (
    <div style={{ backgroundColor: "#F3F1F5" }}>
      <div className="container">
        <div className="row align-items-start">
          <div className="col">
            <h1 style={{ textAlign: "center" }}>Accounting and Bookkeeping</h1>
          </div>
        </div>
        <br />
        
        <div className="row align-items-start">
          <div className="col">
            <h5>
              {" "}
              Whether you're a startup or an established business, keeping track
              of your financials is essential to making informed business
              decisions. Accounting is the process of tracking income and
              expenses while Bookkeeping is the process of keeping records of
              financial transactions and preparing financial statements, such as
              balance sheets and income statements.
            </h5>
          </div>
          
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <div className="sectitle wrapper align-items-start wrap-img">
            <div className="row align-items-start">
              <div className="col-sm-6 r3-first">
                <NavLink exact to="/taxconsultation">
                  <p>Tax Consultation</p>
                  <hr />
                  <i className="bi bi-hfm"></i>
                </NavLink>
              </div>

              <div className="col-sm-6  r3-second">
                <NavLink exact to="/auditadvisories">
                  <p>Audit Advisors</p>
                  <hr />
                  <i className="bi bi-hfm"></i>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
}

export default Aandb;
