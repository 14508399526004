import React, {useEffect, useState} from 'react'
import Aandb from './Aandb'
import AboutUs from './AboutUs'
import Fm from './Fm'
import Slider from './Slider'
import logo from "./assets/img/logo.png";
import 'animate.css'





function Home() {
  const [loading, setLoading] = useState(true)
    useEffect(()=>{
        setTimeout(()=>{
            setLoading(false)
        },4000)
    })
    
    return (

        loading?
        (        
        <div className="anim-logo">
            <img id="logo-loader" class="animate__animated animate__slideInLeft animate__slow" src={logo} style={{height:'300px'}} alt="logo"/>
        </div> 
        )
        :
        (<div>
            <Slider/>
            <AboutUs/>
            <Fm/>
            <Aandb/>
        </div>)
    )
}

export default Home
