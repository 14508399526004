import React from "react";
import tc1 from "./assets/arrow/tax_3.jpg";
import tc2 from "./assets/arrow/tax_2.jpg";
import { NavLink } from "react-router-dom";
function Taxconsultation() {
  return (
    <div>
      <div className="row align-items-start">
        <img class="img-fluid" src={tc1} />
      </div>
      <br />
      <br />
      <div className="row align-items-start">
        <div className="col">
          <h1 style={{ textAlign: "center" }}>Tax and Consultation</h1>
        </div>
      </div>
      <br />
      <br />
      <div className="container">
        <div className="row align-items-start">
          <img
            className="col-md-6"
            src={tc2}
            style={{ width: "500px", height: "500px" }}
            alt="notload"
          />

          <div className="col-md-6">
            <h4>
              In day-to-day operations, a bookkeeper would make sure employees
              are filing invoices and expenses correctly and handling payroll.
              Tax planning and filing is crucial to prevent unwanted surprises
              in business. In addition to reducing filing errors, an accountant
              can help you save money. Because their job is to stay up to date
              with tax codes and regulations. Clear business records helps to
              understand how much money is owed to you, how much you owe and
              will prevent you from making costly or illegal errors. It also
              helps to identify sources of income. A list of records that you
              should keep accurately and orderly: • Payroll and employment taxes{" "}
              <br />
              • Sales and purchases <br />
              • Expenses <br />
              • Bank statements
              <br />
              • Profit and loss statements <br />
              • Cash flow analysis <br />
            </h4>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div class="row gx-0">
        <div class="col-sm-6 btn">
          <div
            class="p-5 border"
            style={{
              backgroundColor: "#082032",

              marginLeft: "0px",
            }}
          >
            <NavLink exact to="/submiturcv">
              <a className="nav-link scrollto" href="#services">
                <h1 style={{ color: "white" }}>Submit ur CV</h1>
              </a>
            </NavLink>
          </div>
        </div>
        <div class="col-sm-6 btn">
          <div
            class="p-5 border"
            style={{
              backgroundColor: "#082032",

              marginLeft: "0px",
            }}
          >
            <a
              className="nav-link scrollto"
              href="https://wiseminds.online"
            >
              <h1 style={{ color: "white" }}>Job Search</h1>
            </a>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
}

export default Taxconsultation;
