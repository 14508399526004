/* eslint-disable import/no-named-as-default */
import { BrowserRouter, Router, Route, Switch } from "react-router-dom";
import "./loader.js";
import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Header from "./Header";
import Home from "./Home";
import About from "./AboutUs";

import Footer from "./Footer";
import Businesssetup from "./Businesssetup";

import Itservices from "./Itservices";
import SubmitCv from "./SubmitCv";
import ContactUs from "./ContactUs";
import Hospitality from "./Hospitality";
import Recruitment from "./Recruitment";
import Cleaningandmaintenance from "./Cleaningandmaintenance";
import Taxconsultation from "./Taxconsultation";
import Auditadvisories from "./Auditadvisories";
import Bankingservices from "./Bankingservices";

class App extends React.Component {
  render() {
    // const activeStyle = { color: "blue" };
    const footerLinksText = ["Home", "About Us", "Services", "Contact Us"];
    return (
      <div>
        <BrowserRouter forceRefresh>
          <Header />

          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/businesssetup" component={Businesssetup} />
            <Route exact path="/itservices" component={Itservices} />
            <Route exact path="/submiturcv" component={SubmitCv} />
            <Route exact path="/bankingservices" component={Bankingservices} />
            <Route exact path="/hospitality" component={Hospitality} />
            <Route exact path="/recruitment" component={Recruitment} />
            <Route
              exact
              path="/cleaningandmaintenance"
              component={Cleaningandmaintenance}
            />
            <Route exact path="/taxconsultation" component={Taxconsultation} />
            <Route exact path="/auditadvisories" component={Auditadvisories} />
            <Route exact path="/contactus" component={ContactUs} />
          </Switch>
          <Footer links={footerLinksText} />
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
