import React from "react";
import aad from "./assets/arrow/audit9.jpg";
import aad1 from "./assets/arrow/audit_1.jpg";
import { NavLink } from "react-router-dom";
function Auditadvisories() {
  return (
    <div>
      <div className="row align-items-start">
        <img class="img-fluid" src={aad}  />
      </div>
      <br />
      <br />
      <div className="row align-items-start">
        <div className="col">
          <h1 style={{ textAlign: "center" }}>Audit Advisors</h1>
        </div>
      </div>
      <br />
      <br />
      <div className="container">
        <div className="row ">
          <img className="col-md-6" src={aad1} alt="notload" />

          <div className="col-md-6 ">
            <p>
              An accountant is responsible for offering strategic financial
              advice in addition to being aware of the company's financial
              situation. Accounts receivable and accounts payable are two
              important records to keep. Accounts receivable is a claim from an
              uncollected amount, usually from a sale on credit while Accounts
              payable is an amount owed to a vendor or credit for completed
              goods or services. It is standard for a business to be audited
              when a bank or investor wants to understand its financial position
              to determine the risk before they invest capital. To prevent your
              business from getting "the bad audit", here are some tips to
              follow: • File and pay your taxes on time <br />• Don't
              incorrectly (or forget to) file business sales and receipts <br />
              • Don't report personal costs as business expenses <br />
              • Keep accurate business records <br />• Know your specific
              business tax reporting obligations
            </p>
          </div>
        </div>
      </div>
      <br />
      <br />

      <div class="row gx-0">
        <div class="col-sm-6 btn">
          <div
            class="p-5 border"
            style={{
              backgroundColor: "#082032",

              marginLeft: "0px",
            }}
          >
            <NavLink exact to="/submiturcv">
              <a className="nav-link scrollto" href="#services">
                <h1 style={{ color: "white" }}>Submit ur CV</h1>
              </a>
            </NavLink>
          </div>
        </div>
        <div class="col-sm-6 btn">
          <div
            class="p-5 border"
            style={{
              backgroundColor: "#082032",

              marginLeft: "0px",
            }}
          >
            <a
              className="nav-link scrollto"
              href="https://wiseminds.online"
            >
              <h1 style={{ color: "white" }}>Job Search</h1>
            </a>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
}

export default Auditadvisories;
