import React from "react";
import abtUs from "./assets/arrow/abtus.jpg";
function AboutUs() {
  return (
    <div style={{backgroundColor:"#F3F1F5"}}>
      <br/><br/>
      <div className="container">
        <div className="row align-items-start">
        
          <div className="col">
            <h1 style={{textAlign:'left'}}>About US</h1>
          </div>
        </div>
        <br/>
        <div className="row align-items-start">
          <div className="col-md-6">
            <h5 >
              {" "}
              Wiseminds is the one stop solution for your business. 
              We will build your business from scratch starting from company formation
              to licensing and banking services to manpower supply along with
              taking care of your taxes and audit, additionally helping the
              growth of your business with our special website development and
              digital marketing packages so that you can be hassle free while
              your business is set up. We at Wiseminds provide the best solution
              possible that is specially crafted for each business that comes to
              us.
            </h5>
          </div>
          <div className="col-md-6 ">
            {" "}
            <img class="img-fluid"
              src={abtUs}
              style={{ width: "500px", height: "350px" }}
              alt="fireSpot"
            />
          </div>
        </div>
      </div>
      <br/><br/>
    </div>
  );
}

export default AboutUs;
