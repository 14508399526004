import React from "react";
import it1 from "./assets/arrow/it9.jpg";
import it2 from "./assets/arrow/IT_2.jpg";
import { NavLink } from "react-router-dom";
function Itservices() {
  return (
    <div>
      <div className="row align-items-start">
        <img class="img-fluid" src={it1} />
      </div>
      <br />
      <br />
      <div className="row align-items-start">
        <div className="col">
          <h1 style={{ textAlign: "center" }}>IT Services</h1>
        </div>
      </div>
      <br />
      <br />
      <div className="container">
        <div className="row align-items-start">
          {" "}
          <img
            className="col-md-6"
            src={it2}
            style={{ width: "500px", height: "500px" }}
            alt="notload"
          />
          <div className="col-md-6">
            <h4>
              We provide you a wide range of It services starting from web
              design to website development to digital marketing from the
              specialists in the field through our business partner Susanniya
              Infotech at competitive prices that is hard to beat. With every
              business set up we offer you a website and digital marketing or
              social media marketing packages that is specially customized
              depending on your business needs. Some of the IT services we
              provide that are essential to every business are: <br />
              Logo Designing
              <br />
              Search Engine Optimization <br />
              Web Development <br />
              App Development <br />
              Accounting Software <br />
              CRM Software Development
            </h4>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div class="row gx-0">
        <div class="col-sm-6 btn">
          <div
            class="p-5 border"
            style={{
              backgroundColor: "#082032",

              marginLeft: "0px",
            }}
          >
            <NavLink exact to="/submiturcv">
              <a className="nav-link scrollto" href="#services">
                <h1 style={{ color: "white" }}>Submit ur CV</h1>
              </a>
            </NavLink>
          </div>
        </div>
        <div class="col-sm-6 btn">
          <div
            class="p-5 border"
            style={{
              backgroundColor: "#082032",

              marginLeft: "0px",
            }}
          >
            <a
              className="nav-link scrollto"
              href="https://wiseminds.online"
            >
              <h1 style={{ color: "white" }}>Job Search</h1>
            </a>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
}

export default Itservices;
