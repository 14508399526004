import React from "react";
import cl1 from "./assets/arrow/cleaning9.jpg";
import cl2 from "./assets/arrow/cleaning7.jpg";
import { NavLink } from "react-router-dom";
function Cleaningandmaintenance() {
  return (
    <div>
      <div className="row align-items-start">
        <img class="img-fluid" src={cl2} />
      </div>
      <br />
      <div className="row align-items-start">
        <div className="col">
          <h1 style={{ textAlign: "center" }}>Cleaning and Maintenance</h1>
        </div>
      </div>
      <br />
      <br />
      <div className="container">
        <div className="row align-items-start">
          <img className="col-md-6 img-fluid" src={cl1} alt="notload" />

          <div className="col-md-6">
            <h4>
              Hire temporary employees on Daily and Monthly Rates. Provide us
              with the Job Description and choose from ready to deploy
              candidates with relevant experience. We provide trusted and
              reliable maids for cleaning services through easy booking and
              affordable cost. Custom cleaning packages at special rates are
              also available. Our staff are friendly and professionally perfect
              in their job.
            </h4>
          </div>
        </div>
      </div>
      <br />
      <br />

      <div class="row gx-0">
        <div class="col-sm-6 btn">
          <div
            class="p-5 border"
            style={{
              backgroundColor: "#082032",

              marginLeft: "0px",
            }}
          >
            <NavLink exact to="/submiturcv">
              <a className="nav-link scrollto" href="#services">
                <h1 style={{ color: "white" }}>Submit ur CV</h1>
              </a>
            </NavLink>
          </div>
        </div>
        <div class="col-sm-6 btn">
          <div
            class="p-5 border"
            style={{
              backgroundColor: "#082032",

              marginLeft: "0px",
            }}
          >
            <a
              className="nav-link scrollto"
              href="https://wiseminds.online"
            >
              <h1 style={{ color: "white" }}>Job Search</h1>
            </a>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
}

export default Cleaningandmaintenance;
