import React from "react";
import rt from "./assets/arrow/rmnt3.jpg";
import rt1 from "./assets/arrow/rmnt0.jpg";
import { NavLink } from "react-router-dom";
function Recruitment() {
  return (
    <div>
      <div className="row align-items-start">
        <img class="img-fluid" src={rt} />
      </div>
      <br />
      <div className="row align-items-start">
        <div className="col">
          <h1 style={{ textAlign: "center" }}>Recruitment</h1>
        </div>
      </div>
      <br />
      <br />
      <div className="container">
        <div className="row align-items-start">
          {" "}
          <img
            className="col-md-6"
            src={rt1}
            style={{ width: "500px", height: "500px" }}
            alt="notload"
          />
          <div className="col-md-6">
            <h4>
              Recruitment refers to the process of identifying, attracting,
              interviewing, selecting, hiring and onboarding employees. We do
              everything from the identification of a staffing need to
              fulfilling it. While every recruitment process is unique to the
              organization, there are 15 essential steps that we have listed
              here: Identify the hiring need <br />
              Devise a recruitment plan <br />
              Write a job description
              <br />
              Advertise the position <br />
              Recruit the position <br />
              Review applications
              <br />
              Phone Interview/Initial Screening <br />
              Interviews <br />
              Applicant Assessment
              <br />
              Background Check <br />
              Decision <br />
              Reference Check
              <br />
              Job offer <br />
              Hiring
              <br />
              Onboarding
              <br />
            </h4>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div class="row gx-0">
        <div class="col-sm-6 btn">
          <div
            class="p-5 border"
            style={{
              backgroundColor: "#082032",

              marginLeft: "0px",
            }}
          >
            <NavLink exact to="/submiturcv">
              <a className="nav-link scrollto" href="#services">
                <h1 style={{ color: "white" }}>Submit ur CV</h1>
              </a>
            </NavLink>
          </div>
        </div>
        <div class="col-sm-6 btn">
          <div
            class="p-5 border"
            style={{
              backgroundColor: "#082032",

              marginLeft: "0px",
            }}
          >
            <a
              className="nav-link scrollto"
              href="https://wiseminds.online"
            >
              <h1 style={{ color: "white" }}>Job Search</h1>
            </a>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
}

export default Recruitment;
