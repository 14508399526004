import React from "react";
import mapimg from "./assets/arrow/contactus.jpg";
function ContactUs() {
  return (
    <div style={{ backgroundColor: "#082032" }}>
      <div className="row align-items-start">
        <img class="img-fluid" src={mapimg} style={{ height: "530px" }} />
      </div>
      <br />
      <br />
      <div className="row align-items-start">
        <div className="col">
          <h1 style={{ textAlign: "center", color: "white" }}>Contact Us</h1>
        </div>
      </div>
      <br />
      <br />
      <div className="row align-items-start" style={{marginLeft:'135px'}}>
               
               <div className="col-lg-12">
                 <iframe
                   width="1056"
                   height="377"
                   frameborder="0"
                   scrolling="no"
                   marginheight="0"
                   marginwidth="0"
                   id="gmap_canvas"
                   src="https://maps.google.com/maps?width=1056&amp;height=377&amp;hl=en&amp;q=oud%20mehta%20Dubai+(UAE)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                 ></iframe>
                 
               </div>
             </div>
             <br />
      <br />

      <div class="container">
        <div class="container px-4">
          <div class="row gx-0">
            <div class="col">
              <div class="p-3 border" style={{ backgroundColor: "white" }}>
                <div className="col ">
                  <div style={{ color: "#082032" }}>
                    <h3>
                      Wiseminds<span>.</span>
                    </h3>
                    <p>
                      <i class="bi bi-geo-alt-fill"></i> Dubai UAE <br />
                      <i class="bi bi-geo-alt-fill"></i> Dubai UAE
                      <br />
                      <i class="bi bi-geo-alt-fill"></i> Dubai UAE <br />
                      <strong>
                        <i class="bi bi-phone"></i> Phone:+971 552602997
                      </strong>{" "}
                      <br />
                      <strong>
                        <i class="bi bi-telephone-fill"></i> Landline:045915242
                      </strong>{" "}
                      <br />
                      <strong>
                        <i class="bi bi-envelope-fill"></i> Email:
                      </strong>{" "}
                      info@wiseminds.ae
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="p-3 border " style={{ backgroundColor: "white" }}>
                <div style={{ color: "#082032" }}>
                  <h3>
                    Wiseminds<span>.</span>
                  </h3>
                  <p>
                    Dubai UAE <br />
                    Dubai UAE
                    <br />
                    Dubai UAE <br />
                    <strong>Phone:+971 552602997</strong> <br />
                    <strong>Landline:045915242</strong> <br />
                    <strong>Email:</strong> info@wiseminds.ae
                    <br />
                  </p>
                </div>
              </div>
              <br />
            </div>
            
            
          </div>
        </div>
      </div>
      <br/>
      
            <br/>
            <br/>
    </div>
  );
}

export default ContactUs;
