import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/vendor/bootstrap-icons/bootstrap-icons.css";
import { NavLink } from "react-router-dom";
function Footer() {
  return (
    <div>
      <footer id="footer">
        {/* <div className="footer-newsletter">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <h4>Join Our Newsletter</h4>
                <p>hello,how are you</p>
                <form action method="post">
                  <input type="email" name="email" /><input type="submit" defaultValue="Subscribe" />
                </form>
              </div>
            </div>
          </div>
        </div> */}
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 footer-contact">
                <h3>
                  Wiseminds<span></span>
                </h3>

                <p>
                  <i class="bi bi-geo-alt-fill"></i>{" "}
                  <strong> Address:- Office # 212, 2nd Floor,</strong> <br />
                  <strong> Rashid AL Makhawi Building, </strong> <br />
                  <strong> Opp. Nasar Club, Oud Metha </strong>
                  <br />
                  <strong> Dubai UAE </strong> <br />
                  <br />
                  <i class="bi bi-phone"></i>{" "}
                  <strong>Phone: +971-55-2602997</strong> <br />
                  <i class="bi bi-telephone-fill"> </i>
                  <strong>Landline: 045915242 </strong> <br />
                  <i class="bi bi-envelope-fill"></i> <strong>Email:</strong>{" "}
                  <strong>info@wiseminds.ae</strong>
                  <br />
                </p>
              </div>
              <div className="col-lg-4 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <NavLink exact to="/bankingservices">
                      Banking Services
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact to="/recruitment">
                      Recruitment
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact to="/cleaningandmaintenance">
                      Cleaning Services
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact to="/taxconsultation">
                      Tax Consultation
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact to="/auditadvisories">
                      Audit Advisors
                    </NavLink>
                  </li>
                  <li>
                  <NavLink exact to="/itservices">
                    
                    IT Services
                    </NavLink>
                  </li>
                </ul>
              </div>
              {/* <div className="col-lg-3 col-md-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li><i className="bx bx-chevron-right" /> <a href="#">Web Design</a></li>
                  <li><i className="bx bx-chevron-right" /> <a href="#">Web Development</a></li>
                  <li><i className="bx bx-chevron-right" /> <a href="#">Product Management</a></li>
                  <li><i className="bx bx-chevron-right" /> <a href="#">Marketing</a></li>
                  <li><i className="bx bx-chevron-right" /> <a href="#">Graphic Design</a></li>
                </ul>
              </div> */}
              <div className="col-lg-4 col-md-6 footer-links">
                <h4>Our Social Networks</h4>
                <p>Please follow us on social media.</p>
                <div className="social-links mt-3">
                  <a href="#" className="facebook">
                    <i className="bi bi-facebook" />
                  </a>
                  <a
                    href="https://www.instagram.com/invites/contact/?i=vm8doa3i719&utm_content=mt1f28d"
                    className="instagram"
                  >
                    <i className="bi bi-instagram" />
                  </a>
                  <a href="#" className="google-plus">
                    <i className="bi bi-whatsapp" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/wiseminds-facilities-management-services"
                    className="linkedin"
                  >
                    <i className="bi bi-linkedin" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container py-4">
          <div className="copyright">
            © Copyright 2021{" "}
            <strong>
              <span>Wiseminds</span>
            </strong>
            . All Rights Reserved
          </div>
          <div className="credits">
            Designed by{" "}
          <b>  <a
              style={{ color: "white" }}
              href="https://susanniyainfotech.com/"
            >
              SusanniyaInfotech
            </a></b>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
