import React from "react";
import hp1 from "./assets/arrow/hospitality9.jpg";
import hp2 from "./assets/arrow/hospitality_2.jpg";
import { NavLink } from "react-router-dom";
function Hospitality() {
  return (
    <div>
      <div className="row align-items-start">
        <img class=" col-lg-12 img-fluid" src={hp1} />
      </div>
      <br />
      <br />
      <div className="row align-items-start">
        <div className="col">
          <h1 style={{ textAlign: "center" }}>
            Hospitality - Hotels and Restaurants
          </h1>
        </div>
      </div>
      <br />
      <br />
      <div className="container">
        <div className="row align-items-start">
          {" "}
          <img className="col-md-6 img-fluid" src={hp2} alt="notload" />
          <div className="col-md-6">
            <h4>
              Wiseminds provide complete assistance for Hotels and Restaurants.
              We help in building to set up and staffing of hotels and
              restaurants.
            </h4>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div class="row gx-0">
        <div class="col-sm-6 btn">
          <div
            class="p-5 border"
            style={{
              backgroundColor: "#082032",

              marginLeft: "0px",
            }}
          >
            <NavLink exact to="/submiturcv">
              <a className="nav-link scrollto" href="#services">
                <h1 style={{ color: "white" }}>Submit ur CV</h1>
              </a>
            </NavLink>
          </div>
        </div>
        <div class="col-sm-6 btn">
          <div
            class="p-5 border"
            style={{
              backgroundColor: "#082032",

              marginLeft: "0px",
            }}
          >
            <a
              className="nav-link scrollto"
              href="https://wiseminds.online"
            >
              <h1 style={{ color: "white" }}>Job Search</h1>
            </a>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
}

export default Hospitality;
