// import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";
// import "./assets/vendor/bootstrap-icons/bootstrap-icons.css";
import logo from "./assets/img/logo.png";
import { NavLink } from "react-router-dom";

import React, { Component } from "react";
import { Container, Navbar, NavDropdown, Nav } from "react-bootstrap";

class Header extends Component {
  state = {
    searchTerm: "",
  };

  onSearchTermChanged = (event) => {
    this.setState({
      searchTerm: event.target.value,
    });
  };
  render() {
    return (
      <div>
        <section id="topbar" className="d-flex align-items-center">
          <div className="container d-flex justify-content-center justify-content-md-between">
            <div className="contact-info d-flex align-items-center">
              <i className="bi bi-envelope d-flex align-items-center">
                <a href="mailto:contact@example.com">info@wiseminds.ae</a>
              </i>
              <i className="bi bi-telephone-fill d-flex align-items-center ms-4">
                <span>045915242</span>
              </i>
            </div>
            <div className="social-links d-none d-md-flex align-items-center">
              <a href="#" className="twitter">
                <i className="bi bi-whatsapp" />
              </a>
              <a href="#" className="facebook">
                <i className="bi bi-facebook" />
              </a>
              <a
                href="https://www.instagram.com/invites/contact/?i=vm8doa3i719&utm_content=mt1f28d"
                className="instagram"
              >
                <i className="bi bi-instagram" />
              </a>
              <a
                href="https://www.linkedin.com/company/wiseminds-facilities-management-services"
                className="linkedin"
              >
                <i className="bi bi-linkedin" />
              </a>
            </div>
          </div>
        </section>

        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
          <Container>
            <Navbar.Brand>
              <NavLink exact to="/">
                <img
                  className="img-fluid"
                  src={logo}
                  style={{
                    height: "80px",
                    width: "140px",
                    marginLeft: "-13px",
                  }}
                />
              </NavLink>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link>
                  <NavLink exact to="/">
                    Home
                  </NavLink>
                </Nav.Link>

                <Nav.Link>
                  <NavLink className="nav-link" exact to="/businesssetup">
                    Business set-up
                  </NavLink>
                </Nav.Link>

                <NavDropdown
                  title="Facilities Management"
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item>
                    {" "}
                    <NavLink className="nav-link" exact to="/hospitality">
                      Hospitality
                    </NavLink>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    {" "}
                    <NavLink className="nav-link" exact to="/bankingservices">
                      Banking Services
                    </NavLink>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    {" "}
                    <NavLink className="nav-link" exact to="/recruitment">
                      Recruitment
                    </NavLink>
                  </NavDropdown.Item>

                  <NavDropdown.Item>
                    {" "}
                    <NavLink
                      className="nav-link"
                      exact
                      to="/cleaningandmaintenance"
                    >
                      Cleaning Services
                    </NavLink>
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown
                  title="Accounting & Bookkeeping"
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item>
                    {" "}
                    <NavLink exact to="/taxconsultation">
                      Tax Consultation
                    </NavLink>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    {" "}
                    <NavLink exact to="/auditadvisories">
                      Audit Advisors
                    </NavLink>
                  </NavDropdown.Item>
                </NavDropdown>

                <Nav.Link>
                  <NavLink exact to="/itservices">
                    <a className="nav-link scrollto" href="#about">
                      IT Services
                    </a>
                  </NavLink>
                </Nav.Link>

                <Nav.Link>
                  <NavLink exact to="/submiturcv">
                    <a className="nav-link scrollto" href="#services">
                      Submit CV
                    </a>
                  </NavLink>
                </Nav.Link>

                <Nav.Link>
                  <NavLink exact to="/contactus">
                    <a className="nav-link scrollto">Contact</a>
                  </NavLink>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    );
  }
}
export default Header;
