import React from "react";
import hsptl from "./assets/arrow/hospitality.jpg";
import Cf from "./assets/arrow/companyformation.jpg";
import Bs from "./assets/arrow/bankopen.jpg";
import Rcmt from "./assets/arrow/rmnt1.jpg";
import It from "./assets/arrow/IT.jpg";
import Candm from "./assets/arrow/cleaning.jpg";
import { NavLink } from "react-router-dom";

function Fm() {
  return (
    <div style={{ backgroundColor: "#F3F1F5" }}>
      <div className="container">
        <div className="row align-items-start">
          <div className="col">
            <h1 style={{ textAlign: "center" }}>Facilities Management</h1>
          </div>
        </div>
        <br />
        
        <div className="sectitle wrapper align-items-start wrap-img">
          <div className="col">
            <h5>
              {" "}
              Facilities management encompasses multiple disciplines to ensure
              functionality, comfort, safety and efficiency of the built
              environment by integrating people, place, process and technology.
              It contributes to the organization’s bottom line through their
              responsibility for maintaining what are often an organization’s
              largest and most valuable assets, such as property, buildings,
              equipment and other environments that house personnel,
              productivity, inventory and other elements of operation. Here are
              some of the ways we contribute to an organization’s base to
              business strategy:
            </h5>
          </div>
<br/>
          <div className="row align-items-start">
            <div className="col-sm-6 r1-first">
              <NavLink exact to="/bankingservices">
                <p>Banking Services</p>
                <hr />
                <i className="bi bi-hfm"></i>
              </NavLink>
            </div>

            <div className="col-sm-3  r1-second">
              <NavLink exact to="/hospitality">
                <p>
                  Hospitality <br />
                  Hotels&Restaurants
                </p>
                <hr />
                <i className="bi bi-hfm"></i>
              </NavLink>
            </div>

            <div className="col-sm-3 r1-third">
              <NavLink exact to="/itservices">
                <p>IT Services</p>
                <hr />
                <i className="bi bi-hfm"></i>
              </NavLink>
            </div>

            <br />
          </div>

          <div className="row align-items-start">
            <div className="col r2-first">
              <NavLink exact to="/cleaningandmaintenance">
                <p>Cleaning Services</p>
                <hr />
                <i className="bi bi-hfm"></i>
              </NavLink>
            </div>

            <div className="col r2-second">
              <NavLink exact to="/recruitment">
                <p>Recruitment</p>
                <hr />
                <i className="bi bi-hfm"></i>
              </NavLink>
            </div>

            <div className="col r2-third">
              <NavLink exact to="/businesssetup">
                <p>Company Formation</p>
                <hr />
                <i className="bi bi-hfm"></i>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
}

export default Fm;
