import React from 'react'
import crsl from './assets/img/slider/e.jpg';                                            
import crsl2 from './assets/img/slider/h.jpg';
import crsl3 from './assets/img/slider/c.jpg';
import crsl4 from './assets/img/slider/f.jpg';
import crsl5 from './assets/img/slider/g.jpg';
import Carousel from 'react-bootstrap/Carousel'
function Slider() {
  return (
  <div>
    <Carousel>
  <Carousel.Item interval={3500}>
    <img
      className="d-block w-100"
      src={crsl}
      alt="First slide"
      
    />
    <Carousel.Caption>
     
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item interval={3500}>
    <img
      className="d-block w-100"
      src={crsl2}
      alt="Second slide"
      
    />
    <Carousel.Caption>
     
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item interval={3500}>
    <img
      className="d-block w-100"
      src={crsl3}
      alt="Third slide"
      
    />
    <Carousel.Caption>
     
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item interval={3500}>
    <img
      className="d-block w-100"
      src={crsl4}
      alt="Forth slide"
      
    />
    <Carousel.Caption>
     
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item interval={3500}>
    <img
      className="d-block w-100"
      src={crsl5}
      alt="Fifth slide"
      
    />
    <Carousel.Caption>
     
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>
  </div>
   
  )
}

export default Slider
